<template>
    <a v-on:click="handleConnect">{{ connected ? shortAddr : wallet_addr }}</a>
</template>

<script>
	import Web3 from 'web3'
	import MetaMaskOnboarding from '@metamask/onboarding'
	import detectEthereumProvider from '@metamask/detect-provider';

	export default {
		name: "Metamask",
		props: {
			button_text: {
				type: String,
				default: function() {
					return "Connect Wallet"
				}
			}
		},
		data() {
			return {
				metamask_installed: false,
				connected: false,
				wallet_addr: "",
				onboarding: null,
			}
		},
		computed: {
            shortAddr: function () {
                return `${this.wallet_addr.substr(0, 6)}...${this.wallet_addr.substr(-4)}`;
            },
        },
		mounted() {
			this.wallet_addr = this.button_text
            //this.handleConnect();
        },
		methods: {
            connectWallet: function () {
                if (typeof window.ethereum !== 'undefined') {
                    console.log('MetaMask is installed!');

					this.ethEnabled(); //Enable etherum connection
					return
				}
			},

			ethEnabled: function (provider) {
				console.log("Metamask - Setting provider", provider);
				window.web3 = new Web3(provider);
			},

			handleConnect: function () {
				detectEthereumProvider().then((provider) => {
					if (provider) {
						this.triggerAuth(provider);
					} else {
						console.log('Please install MetaMask!');
					}
				});
			},

			triggerAuth: function (provider) {
				this.onboarding = new MetaMaskOnboarding();

				//Created check function to see if the MetaMask extension is installed
				const isMetaMaskInstalled = () => {
					//Have to check the ethereum binding on the window object to see if it's installed
					return Boolean(provider && provider.isMetaMask);
				};

                //------Inserted Code------\\
                const MetaMaskClientCheck = () => {
                    //Now we check to see if MetaMask is installed
                    this.metamask_installed = isMetaMaskInstalled();
                    if (!this.metamask_installed) {
                        //If it isn't installed we ask the user to click to install it
                        this.wallet_addr = 'Click here to install MetaMask!';
                    } else {
                        //If it is installed we change our button text
                        this.wallet_addr = 'Connect';
                    }
                };
                MetaMaskClientCheck();
                //------/Inserted Code------\\


                if (!this.metamask_installed) {
                    this.wallet_addr = 'Onboarding in progress';
                    // this.connect_btn_disabled = true;
                    //On this object we have startOnboarding which will start the onboarding process for our end user
                    this.onboarding.startOnboarding();
                }


                if (typeof window.ethereum == 'undefined') {
                    this.wallet_addr = "Unable to connect";
                    return
                }

                console.log('MetaMask is installed!');

                this.ethEnabled(provider); //Enable etherum connection

                provider.request({method: 'eth_requestAccounts'}).then((accounts) => {
                    console.log("Public address", accounts[0]);
                    this.wallet_addr = accounts[0];
                    this.connected = true;
                    this.$emit("wallet-connected", this.wallet_addr); //Fire connected event
                    this.$store.commit('set_wallet_addr', this.wallet_addr);
                    this.$store.commit('set_wallet_connected', this.connected);
					this.$emit("connected", this.connected)
                }).catch(() => {
                    this.wallet_addr = "Unable to connect"
                });
            }
        },
    }
</script>

<style scoped>
	a {
		color: white !important;
	}

</style>