<template>
	<div class="container-fluid p-3 mt-5">
		<div class="container text-center">
			
			<div class="row text-center">
				<div class="col">
					<img src="@/assets/images/alpha_black.png" class="login_img mr-3">
					<h1 class="mt-1 mb-5">ALPHABATEM</h1>
				</div>
			</div>
			
			<div class="login-container col-8 offset-2 wow fadeIn">
				<h1 class="">Login</h1>
				
				<div class="row">
					<div class="col-12 my-2">
						<Phantom @connected="onConnect" button_text="Phantom" class="btn btn-primary btn-block"></Phantom>
					</div>
					<div class="col-12 my-2">
						<WalletConnect @connected="onConnect" class="btn btn-primary btn-block"></WalletConnect>
					</div>
					<div class="col-12 my-2">
						<Metamask @connected="onConnect" button_text="MetaMask" class="btn btn-primary btn-block"></Metamask>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Metamask from "../../components/wallet/Metamask";
	import WalletConnect from "../../components/wallet/WalletConnect";
	import Phantom from "../../components/wallet/Phantom";
	import store from "../../store";
	
	export default {
		name: "Login",
		components: {Phantom, WalletConnect, Metamask},
		methods: {
			onConnect: function () {
				let nextPage = "/profile"
				
				console.log("History", store.state.routerHistory);
				if (store.state.routerHistory.length > 0)
					nextPage = store.state.routerHistory[store.state.routerHistory.length - 1]
				
				console.log("Connected, redirecting to: ", nextPage)
				this.$router.push(nextPage);
			}
		}
	}
</script>

<style scoped>
	h1, h2, h3 {
		color: white;
	}
	
	h1 {
		line-height: 2em;
		font-size: 3em;
	}
	
	.login_img {
		max-height: 100px;
		border-radius: 14px;
		box-shadow: black 0px 0px 10px;
	}
	
	.login-container {
		color: white;
		background: #0E0E0F;
		border-radius: 8px;
		padding: 2%;
		transition: all 0.3s ease-in-out;
	}
</style>