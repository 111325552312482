<template>
	<div class="">
		<Frame></Frame>
	</div>
</template>

<script>
	import Frame from "../../components/game/Frame";
	
	export default {
		name: "Dashboard",
		components: {Frame}
	}
</script>

<style scoped>

</style>