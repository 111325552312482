<template>
	<div class="container-fluid p-0 visit-room show">
		<div class="row text-center" id="game-container">
			<div class="col-12">
				<iframe frameborder="none" id="game-demo-frame" loading="lazy" src="/game/babilu.html"
					width="100%" height="100%"></iframe>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		name: "Frame",
		props: {
			room_id: String,
		},
		mounted() {
			let container = document.getElementById("game-container");
			let frame = document.getElementById("game-demo-frame");
			let percent = 1; //Scale
			
			frame.width = container.clientWidth * percent;
			frame.height = frame.width / 16 * 9;
		}
	}
</script>

<style scoped>
	.visit-room {
		background-color: #141B25;
	}
	
	iframe {
		display: block;
	}
</style>